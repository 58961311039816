body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(60deg, #2e5372 0%, #39696D 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Responsive */
@media (max-width: 1200px) {
  .MuiCardActions-root{
    display: block;
    text-align: center;
  }
  .MuiCardActions-root button{
    padding:0 10px 4px;
  }
}
@media (max-width: 1024px) {
  .mobile{
    margin:2.5% !important;
    padding:0 !important;
    width: 100% !important;
  }
  .ctaButtons{
    width:100%;
    text-align:center !important;
    display:block !important;
    margin: 10px auto 10px !important;
  }
  .MuiButton-root{
    width:90%;
    display:block !important;
    margin: auto auto 10px !important;
  }

  .MuiGrid-grid-xs-5 {
    flex-grow: 0;
    max-width: 50% !important;
    flex-basis: 50% !important;
  }
  .MuiGrid-grid-xs-3 {
    padding:0 !important;
  }
  .makeStyles-bigCard-3{
    margin-top:-16px !important;
  }
}
@media (max-width: 768px) {
  .MuiPaper-root,
  .MuiCard-root,
  .MuiGrid-root,
  .MuiGrid-container,
  .MuiGrid-item{
  }
  .MuiGrid-grid-xs-3,
  .MuiGrid-grid-xs-5,
  .MuiGrid-grid-xs-9 {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
    padding:0 !important;
  }
  .makeStyles-bigCard-3{
    margin-top:16px !important;
    min-width: 100% !important;
  }
  .mobile{
    margin:2.5% 0 2.5% !important;
    padding:10px !important;
  }
}
